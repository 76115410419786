export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campsite"])},
  "general": {
    "liked_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked by"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["person"])},
    "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persons"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account yet?"])},
    "has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already got an account?"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "userCampsites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your entries"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prev"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "teaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short description"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "description-subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a brief description."])}
  },
  "navigation": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About campsite"])}
  },
  "campsite": {
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "visibility": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the visibility of this entry."])},
      "public": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible for all campsite users."])}
      },
      "friends": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible to all your campsite friends."])}
      },
      "private": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible only for you."])}
      }
    },
    "sanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanity"])},
    "pitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitches"])},
    "max_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max days"])},
    "costs_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs per night"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "dogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dogs"])},
    "caravan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caravan"])},
    "barrierfree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrier free"])},
    "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "environmental_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental zone"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi"])},
    "toilets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilets"])},
    "showers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showers"])},
    "guarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarded"])},
    "washing_sink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing sink"])},
    "washing_machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing machine"])},
    "clothes_dryer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothes dryer"])},
    "shading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shading"])},
    "lightning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lighting"])},
    "electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electicity"])},
    "freshwater_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshwater supply"])},
    "freshwater_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshwater connection"])},
    "graywater_disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graywater disposal"])},
    "graywater_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graywater connection"])},
    "toilet_disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet disposal"])},
    "garbage_disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garbage disposal"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "functions_menu": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
      "images": {
        "btn": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit images"])},
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload images"])}
        }
      }
    },
    "create": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new campsite"])},
      "button_find_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find me"])},
      "locating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["locating ..."])},
      "button_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create entry"])},
      "button_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show summary"])},
      "step_1": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag the marker to the campsites position or click the \"find me\" button."])},
        "find_by_poi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of the place you are at or click the \"find me\" button"])}
      },
      "step_2": {
        
      },
      "step_6": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
        "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below you find the summary for this campsite. Review the info briefly before creating the entry, please."])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the next step you will be redirected to the detail view where you can upload pictures."])}
      }
    }
  },
  "imagemodal": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit images"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the delete button to delete a single image."])}
  },
  "upload": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload images"])},
    "filesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])},
    "noimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image"])},
    "drag_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop file here"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "btn": {
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start upload"])},
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse files"])}
    },
    "errors": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File to big"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type not supported"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
      "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation canceled"])},
      "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network error"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server error"])},
      "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])}
    }
  }
}