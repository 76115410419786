import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n'
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import AvatarEditor from '@/components/AvatarEditor.vue';
import VueUploadComponent from 'vue-upload-component';

// Import service worker
import './registerServiceWorker';

// Import styles
import 'cropperjs/dist/cropper.css';
import './app.scss'

// import the fontawesome core
import { library } from '@fortawesome/fontawesome-svg-core'

// import font awesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import specific icons
import {  faUser, faHeart, faBookmark, faComment, faImage, faPlusSquare, faSquarePlus, faTrashCan, faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import {  faCrosshairs, faChevronRight, faChevronLeft, faArrowRotateRight, faXmark, faCaravan, faMapLocationDot, faCamera, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

// add icons to the library
library.add( faUser, faHeart, faBookmark, faComment, faImage, faPlusSquare, faSquarePlus, faTrashCan, faCircleCheck, faCircleXmark, faCrosshairs, faChevronRight, faChevronLeft, faArrowRotateRight, faXmark, faCaravan, faMapLocationDot, faCamera, faEllipsisVertical );

const app = createApp(App)
	.use(i18n)
	.use(store)
	.use(router)
	.use(VueAxios, axios)
	.use(VueGoogleMaps, {
		load: {
			key: 'AIzaSyBPkxxprrUFZnu5gbxh2Ub4ZZXYiRggeA0',
			language: 'de',
			libraries: "places"
		},
		autobindAllEvents: false,
	})
	.component('FontAwesomeIcon', FontAwesomeIcon)
	.component('AvatarEditor', AvatarEditor)
	.component('FileUpload', VueUploadComponent);

app.mount('#app')
