<template>
	<div style="padding: 100px 0">
	
		<CRow>
			<CCol xs>
				<h2>{{ $t('general.userCampsites') }}</h2>
								
				<CCard v-for="campsite in userCampsites" @click="detailView( campsite )" class="border-0 border-bottom pb-3 mb-3">
					<CRow class="g-0">
						<CCol :xs="3">


							<CCardImage class="w-auto rounded-0" v-if="campsite.images[0]" orientation="top" width="100" height="100" :src="appUrl + '/storage/files/' + campsite.images[0].name " />
							<CCardImage class="w-auto rounded-0" v-else orientation="top" src="https://picsum.photos/100/100?random=31" />
						</CCol>
						<CCol :xs="9">
							<CCardBody class="py-0">
								<CCardText class="mb-0 fw-bold">{{ campsite.title}}</CCardText>
								<CCardText class="mb-1 small">Eintrag vom: {{  convertDate(campsite.created_at) }} - Land: {{ campsite.country.name}}</CCardText>
								<CCardText class="small">
									<span class="me-1">
										<FontAwesomeIcon 
										:class="{ 'text-danger': campsite.likes_count }" 
										icon="fa-regular fa-heart"
									/>
									</span>
									{{ $t('general.liked_by') }} {{campsite.likes_count}} {{ campsite.likes_count === 1 ? $t('general.person') : $t('general.persons') }}</CCardText>
							</CCardBody>
						</CCol>
					</CRow>
				</CCard>
				
			</CCol>
		</CRow>
	</div>

</template>

<script>

import { 
	CCard, CCardImage, CCardBody, CCardText, CCardTitle, CCardImageOverlay, 
	CImage, CAvatar, CRow, CCol, CListGroup, CListGroupItem, 
	CTable, CTableHead, CTableRow, CTableHeaderCell, CTableDataCell
} from '@coreui/vue'

export default {
	name: 'UserCampsites',
	components: { 
		CCard, CCardImage, CCardBody, CCardText, CCardTitle, CCardImageOverlay,
		CImage, CRow, CCol, CAvatar, CListGroup, CListGroupItem, 
		CTable, CTableHead, CTableRow, CTableHeaderCell, CTableDataCell },
	data() {
		return {
			appUrl: process.env.VUE_APP_URL,	
		};
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
		currentUser() {
			return this.$store.state.auth.user;
		},
		userCampsites() {
			return this.$store.state.campsite.userCampsites;
		}
	},
	created() {
		this.$store.dispatch('campsite/loadUserCampsites', this.$store.state.auth.user.id);
	},
	methods: {
		convertDate( value ) {
			let date = new Date( value );
			let result =   (date.getDate() > 9 ? '' : '0') + date.getDate()
				+'.'
				+((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1)
				+'.'
				+date.getFullYear()
			return result
		},
		detailView( campsite ) {
			this.$store.dispatch('campsite/setCurrentCampsite', campsite);
			this.$router.push({name: 'campsiteDetail', params: { itemId: campsite.id }})
		}
	}
}
</script>