<template>
	<div style="padding: 100px 0">
	
		<CRow>
			<CCol>
				<AvatarEditor />

				<h1 v-if="currentUser" class="mt-4">
					{{currentUser.nickname}}<br/>
					{{currentUser.firstname}} {{currentUser.lastname}}
				</h1>
				<h2  class="mt-4" v-if="currentUser.vantype">{{currentUser.vantype}}</h2>
				<p v-if="currentUser.email">{{$t('general.email')}}: {{currentUser.email}}</p>
				<p v-if="currentUser.country">{{currentUser.country.name}}</p>
				<br/><br/>
			</CCol>
		</CRow>
	</div>

</template>

<script>
import axios from "axios"

import { CAvatar, CRow, CCol, CListGroup, CListGroupItem } from '@coreui/vue'
import AvatarEditor from './AvatarEditor.vue';


export default {
	name: 'ProfileView',
	components: { CRow, CCol, CAvatar, AvatarEditor, CListGroup, CListGroupItem },
	data() {
		return {};
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
		currentUser() {
			return this.$store.state.auth.user;
		},
		userCampsites() {
			return this.$store.state.campsite.userCampsites;
		}
	},
	created() {
		this.$store.dispatch('campsite/loadUserCampsites', this.$store.state.auth.user.id);
	},
	unmounted() {
		//console.log('unmouted');
	},
	methods: {
		detailView( campsite ) {
			this.$store.dispatch('campsite/setCurrentCampsite', campsite);
			this.$router.push({name: 'campsite-detail'})
		}
	}
}
</script>