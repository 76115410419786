<template>
	<div class="about" style="padding: 100px 40px">
		<h1>Die vanstagram Story</h1>
		<p>Als meine Frau und ich die ersten Touren mit unserem Camper gemacht haben, haben wir immer wieder gesagt, wir müssten und diesen oder jenen Stellplattz oder Campingplatz merken, damit wir irgendwann wieder dorthin fahren. Wir haben viele Möglichkeiten diskutiert, die Excel-Tabelle, Markierungen in GoogleMaps Listen, Kärtchen im Karteikasten etc. Bei allen Ansätzen haben wir aber auch immer Nachteile gesehen: In der Excel-Tabelle ist das speichern von Fotos unständlich. Bei GoogleMaps-Listen können wir nicht die Infos hinterlegen, die wir gerne hätten. Karteikärtchen waren mir u analog.</p>
		<p>Ausser den Googlelisten, ist auch das Teilen cooler Plätze mit unseren Freunden auch nicht möglich oder mindestens umständlich.</p>
		<p>Da war dann schnell die Idee einer kleinen eigenen Datenbank-Anwendung geboren, die genau unsere Wünsche erfüllt. Die Anwendung sollte natürlich per Handy errichbar sein, damit wir unsere Einträge von überall machen können. Und, wir wollen unseren Freunden unsere schönsten Stellplpätze teilen können.</p>
		<p>Gesagt getan. Im Frühjahr/Sommer 2022 habe ich dann begonnen, unsere App zu entwickeln. Gemeinsam haben wir immer wieder coole und nützliche Funktionen diskutiert, die in die Entwickluung eingeflossen sind.</p>
		<p>Gunhild & Andre</p>
		<hr/>
		<p class="px-3 py-2 text-center">
			<small class="text-muted font-monospace">
				Mode: {{mode}}<br/>
				AppUrl: {{appUrl}}<br/>
				ApiUrl: {{apiUrl}}
			</small>	
		</p>
	</div>
</template>

<script>
export default {
  data() {
	return {
	  	mode: process.env.VUE_APP_MODE,
	  	appUrl: process.env.VUE_APP_URL,
		apiUrl: process.env.VUE_APP_API_URL,
	}
  }
}
</script>
