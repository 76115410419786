<template>
	<CRow style="padding: 100px 0">
		<CCol>
			<CCard class="p-5 align-self-center">
				<CForm @submit.prevent="handleRegister">
					<h3 class="text-lg font-medium leading-6 text-gray-900">Erstelle Deinen Zugang</h3>
					<CRow>
						<CCol>
							<CFormInput
								type="text"
								id="nickname"
								:floatingLabel="$t('general.username')"
								:aria-label="$t('general.username')"
								:placeholder="$t('general.username')"
								:value="form.nickname"
								@input="form.nickname = $event.target.value"
								v-model="form.nickname"
							/>
						</CCol>
						<CCol>
							<CFormInput
								type="password"
								id="password"
								class="mb-3"
								:floatingLabel="$t('general.password')"
								:placeholder="$t('general.password')"
								:value="form.password"
								@input="form.password = $event.target.value"
								v-model="form.password"
							/>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<CFormInput
								type="text"
								id="firstname"
								class="mb-3"
								:floatingLabel="$t('general.firstname')"
								:placeholder="$t('general.firstname')"
								:value="form.firstname"
								@input="form.firstname = $event.target.value"
								v-model="form.firstname"
							/>
						</CCol>
						<CCol>
							<CFormInput
								type="text"
								id="lastname"
								class="mb-3"
								:floatingLabel="$t('general.lastname')"
								:placeholder="$t('general.lastname')"
								:value="form.lastname"
								@input="form.lastname = $event.target.value"
								v-model="form.lastname"
							/>
						</CCol>
					</CRow>
					<CFormInput
						type="text"
						id="email"
						class="mb-3"
						:floatingLabel="$t('general.email')"
						:placeholder="$t('general.email')"
						:value="form.email"
						@input="form.email = $event.target.value"
						v-model="form.email"
					/>
					<CButton type="submit" class="btn btn-primary mt-3 mb-5">Jetzt Login anlegen</CButton>
				</CForm>
				<div class="divider"></div>
				<div class="text-center">
					{{ $t('general.has_account') }}<br/>
					<router-link to="/login" class="">Jetzt einloggen</router-link>
				</div>
			</CCard>
		</CCol>
	</CRow>

</template>

<script>
import { CCard, CRow, CCol, CForm, CInputGroup, CInputGroupText, CFormInput, CButton } from '@coreui/vue';

export default {
	name: 'RegisterForm',
	components: { CCard, CRow, CCol, CForm, CInputGroup, CInputGroupText, CFormInput, CButton },

	data() {
		return {
			form: {
				email: "",
				password: "",
				nickname: "",
				firstname: "",
				lastname: "",
			}
		}
	},
	methods: {
		handleRegister() {
			this.$store.dispatch("auth/register", this.form).then(
				() => {
					this.$router.push("/login");
				},
				(error) => {
					this.loading = false;
					this.message =
						(error.response &&
						error.response.data &&
						error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
	}
}

</script>

<style scoped lang="scss">
	.register {
		padding: 20px;
	}
</style>