export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campsite"])},
  "general": {
    "liked_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefällt"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
    "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt anmelden!"])},
    "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch kein Konto?"])},
    "has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast schon Deinen Account?"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitzname"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "userCampsites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Einträge"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schließen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abbrechen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löschen"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiter"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "teaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurze Beschreibung"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausführliche Beschreibung"])},
    "description-subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreib bitte einen schönen Text über den Stellplatz."])}
  },
  "navigation": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Profil"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über campsite"])}
  },
  "campsite": {
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeines"])},
    "visibility": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarkeit"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entscheide, wer Deinen Eintrag sehen darf."])},
      "public": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlich"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbar für alle campsite-Nutzer."])}
      },
      "friends": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freunde"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbar nur für Deine campsite-Freunde."])}
      },
      "private": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Du kannst diesen Eintrag sehen."])}
      }
    },
    "sanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauberkeit"])},
    "pitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellplätze"])},
    "max_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standzeit (tg.)"])},
    "costs_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übernachtungskosten"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
    "dogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunde"])},
    "caravan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnwagen"])},
    "barrierfree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrierefrei"])},
    "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservieren"])},
    "environmental_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umweltzone"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN"])},
    "toilets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiletten"])},
    "showers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duschen"])},
    "guarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewacht"])},
    "washing_sink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waschbecken"])},
    "washing_machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waschmaschine"])},
    "clothes_dryer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trockner"])},
    "shading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schatten"])},
    "lightning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beleuchtung"])},
    "electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stromanschluss"])},
    "freshwater_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frischwasser"])},
    "freshwater_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasseranschluss"])},
    "graywater_disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grauwasserentsorgung"])},
    "graywater_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grauwasseranschluss"])},
    "toilet_disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilettenentsorgung"])},
    "garbage_disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müllentsorgung"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breitengrad"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Längengrad"])},
    "functions_menu": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellplatz bearbeiten"])},
      "images": {
        "btn": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder verwalten"])},
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder hochladen"])}
        }
      }
    },
    "create": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellplatz eintragen"])},
      "button_find_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finde mich"])},
      "locating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suche..."])},
      "button_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag anlegen"])},
      "button_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
      "step_1": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ziehe den Marker in der Map möglichst genau an die Position des Stellplatzes. Falls Du gerade dort bist, kannst Du auch den \"Finde mich\"-Button benutzen."])},
        "find_by_poi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst Du einfach den Ort suchen oder Deinen aktuellen Standort verwenden."])}
      },
      "step_6": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
        "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schau Dir alle Deine Angaben noch einmal genau an, bevor Du den Eintrag anlegst. Nutze den \"zurück\"-Button, um Änderungen vorzunehmen."])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im nächsten Schritt wirst Du auf die Detailseite Deines Eintrags weitergeleitet. Dort kannst Du Fotos hochladen."])}
      }
    }
  },
  "imagemodal": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder bearbeiten"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Löschen einzelner Bilder klicke einfach auf den \"Löschen\"-Button."])}
  },
  "upload": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder hochladen"])},
    "filesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateigröße"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wird hochgeladen"])},
    "noimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild fehlt"])},
    "drag_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos hier hin ablegen"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "btn": {
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos hochladen"])},
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder auswählen"])}
    },
    "errors": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei zu groß"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateityp nicht erlaubt"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitüberschreitung"])},
      "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload abgebrochen"])},
      "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerkfehler"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverfehler"])},
      "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff verweigert"])}
    }
  }
}