import { createStore } from 'vuex'
import axios from "axios"
import { auth } from "./auth.module";
import { campsite } from "./campsite.module";

let apiUrl = process.env.VUE_APP_API_URL;

export default createStore({
	modules: { auth, campsite },
	state: {
		countries: null,
	},
	
	mutations: {
		setCountries(state, countries) {
			state.countries = countries;
		},
		setUserImage(state, image){
			state.auth.user.image = image;
		}
	},
	actions: {
		setUserImage({commit}, image) {
			commit('setUserImage', image);
			let user = JSON.parse(localStorage.getItem('user'));
			user.image = image;
			localStorage.setItem('user', JSON.stringify(user));
			this.dispatch('campsite/loadCampsites');

		},
		loadCountries({ commit }) {
			return new Promise((resolve, reject) => {
				axios.get( apiUrl + 'countries' )
					.then(
						(response) => {
							commit('setCountries', response.data.data);
							resolve( response.data.data );
						},
						error => {
							reject(error);
						}
					)
			})
		},
	},
})
