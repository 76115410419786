<template>
	<CRow style="padding: 100px 0">
		<CCol>
			<CCard class="p-5 m-3 align-self-center" >
				<CForm @submit.prevent="handleLogin">
					<h3 class="text-lg font-medium leading-6 text-gray-900">{{ $t('general.login') }}</h3>
					<div 
						v-if="message" 
						:class="{ message: message, error: hasError }"
					>{{message}}</div>
					<CRow>
						<CCol>
							<CFormInput
								type="text"
								id="email"
								:floatingLabel="$t('general.email')"
								:placeholder="$t('general.email')"
								:value="form.email"
								@input="form.email = $event.target.value"
								v-model="form.email"
							/>
						</CCol>
						<CCol>
							<CFormInput
								type="password"
								id="password"
								class="mb-3"
								:floatingLabel="$t('general.password')"
								:placeholder="$t('general.password')"
								:value="form.password"
								@input="form.password = $event.target.value"
								v-model="form.password"
							/>
						</CCol>
					</CRow>
					<CButton type="submit" class="btn btn-primary mt-3 mb-5">{{ $t('general.login') }}</CButton>
					<div class="text-center">
						{{ $t('general.no_account') }}<br/>
						<router-link to="/register">{{ $t('general.create_account') }}</router-link>
					</div>
				</CForm>
			</CCard>
		</CCol>
	</CRow>		
</template>

<script>
import { CCard, CRow, CCol, CForm, CFormInput, CButton } from '@coreui/vue';

export default {
	name: 'LoginForm',
	components: { CCard, CRow, CCol, CForm, CFormInput, CButton },

	data() {
		return {
			loading: false,
			form: {
				email: "",
				password: ""
			},
			message: false,
			hasError: false,
			mode: process.env.VUE_APP_MODE,
			appUrl: process.env.VUE_APP_URL,
			apiUrl: process.env.VUE_APP_API_URL,
		}
	},
	created() {
		this.loading = true;
	},
	methods: {
		handleLogin() {
			this.$store.dispatch("auth/login", this.form).then(
				() => {
					this.$store.dispatch('campsite/loadCampsites');
					this.$router.push("/");
				},
				(error) => {
					this.hasError = true;
					this.message =
						(error.response &&
						error.response.data &&
						error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
	}
}

</script>

<style scoped lang="scss">
	.login {
		padding: 20px;
		
	}
	.message {
		&.error {
			border: 1px solid #ff9a9a;
			background: #ffd3d3;
			padding: 20px;
			text-align: center;
		}
	}
</style>