<template>
		<GMapMap
			:center="center"
			:zoom="5"
			:options="options"
			style="margin: 80px 0; width: width: 100vw; height: 80vh"
		>
			<GMapMarker
				:key="index"
				v-for="(campsite, index) in campsites"
				:position="{
					lat: campsite.latitude,
					lng: campsite.longitude
				}"
				:clickable="true"
				@click="openMarker(campsite.id)"
			>
				<GMapInfoWindow
					:closeclick="true"
					@closeclick="openMarker(null)"
					:opened="openedMarkerID === campsite.id"
				>
					<div>
						<a  @click="detailView( campsite )">{{campsite.title}}</a>
					</div>
				</GMapInfoWindow>
			</GMapMarker>
		</GMapMap>
</template>

<script>

export default {
	name: 'SearchMap',
	data() {
		return {
			openedMarkerID: null,
			center: {
				lat: 51, 
				lng: 3.8
			},
			options: {
			//	mapId: '6139e05c81dcfc1d'		// add custom styling via cloud styling option
			},
		}
	},

	computed: {
		campsites() {
			return this.$store.getters['campsite/getCampsites'];
		}
	},
	methods: {
		openMarker(id) {
			this.openedMarkerID = id
		},
		detailView( campsite ) {
			this.$store.dispatch('campsite/setCurrentCampsite', campsite);
			this.$router.push({name: 'campsite-detail'})
		}
	}
	
}
</script>