import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import LoginComponent from "../components/LoginForm.vue"
import HomeComponent from "../components/Home.vue"
import ProfileComponent from "../components/Profile.vue"
import UserCampsitesComponent from "../components/UserCampsites.vue"
import AboutComponent from "../components/About.vue"
import RegisterComponent from "../components/RegisterForm.vue"
import CampsiteCreate from "../components/CampsiteCreateForm.vue"
import CampsiteDetail from "../components/CampsiteDetail.vue"
import SearchMapComponent from "../components/SearchMap.vue"

import store from "../store/index"

const routes = [
	{
		path: '/',
		name: '',
		component: HomeComponent,
	},
	{
		path: '/about',
		name: 'about',
		component: AboutComponent
	},
	{
		path: '/profile',
		name: 'profile',
		component: ProfileComponent
	},
	{
		path: '/user/campsites',
		name: 'userCampsites',
		props: true,
		component: UserCampsitesComponent
	},
	{
		path: "/search-map",
		name: "seach-map",
		component: SearchMapComponent
	},
	{
		path: "/login",
		name: "login",
		component: LoginComponent
	},
	{
		path: "/register",
		name: "register",
		component: RegisterComponent
	},
	{
		path: "/campsite/create",
		name: "campsiteCreate",
		component: CampsiteCreate
	},
	{
		path: "/campsite/detail/:itemId",
		name: "campsiteDetail",
		props: true,
		component: CampsiteDetail
	},

]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

// make sure the user is authenticated before calling any route
router.beforeEach(async (to, from) => {
	if ( !store.state.auth.status.loggedIn && to.name !== 'login' && to.name !== 'register' ) {
		return { name: 'login' } // redirect the user to the login page
	}
})

export default router
