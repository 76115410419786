<template>
<CForm v-on:submit.prevent="handleCreateCampsite" style="padding: 100px 0">

	<h1 class="mb-5">{{ $t('campsite.create.headline') }}</h1>
	<!-- Form step 1: Maps component -->
	<div v-if="formSteps.step1" class="create-step-1">
		<CRow>
			<CCol>
				<h2>{{ $t('campsite.visibility.headline') }}</h2>
				<p>{{ $t('campsite.visibility.description') }}</p>
			</CCol>
		</CRow>
		<CRow class="px-2">
			<CCol
				@click="form.visibility = 'public'" 
				role="button"
				class="rounded-3 p-3 me-3"
				:class="{ 
					'text-light bg-primary shadow': form.visibility == 'public',
					'border bg-white': form.visibility != 'public',
				}"
			>
				<strong class="mb-2 d-block">{{ $t('campsite.visibility.public.label') }}</strong>
				<small>{{ $t('campsite.visibility.public.description') }}</small>
			</CCol>
			<CCol
				@click="form.visibility = 'friends'" 
				role="button"
				class="rounded-3 p-3"
				:class="{ 
					'text-light bg-primary shadow': form.visibility == 'friends',
					'border bg-white': form.visibility != 'friends',
				}"
			>
				<strong class="mb-2 d-block">{{ $t('campsite.visibility.friends.label') }}</strong>
				<small>{{ $t('campsite.visibility.friends.description') }}</small>
			</CCol>
			<CCol
				@click="form.visibility = 'private'" 
				role="button"
				class="rounded-3 p-3 ms-3"
				:class="{ 
					'text-light bg-primary shadow': form.visibility == 'private',
					'border ms-3 bg-white': form.visibility != 'private',
				}"
			>
				<strong class="mb-2 d-block">{{ $t('campsite.visibility.private.label') }}</strong>
				<small>{{ $t('campsite.visibility.private.description') }}</small>
			</CCol>
		</CRow>
		
		<CRow class="mt-5">
			<CCol>
				<h2>{{ $t('campsite.create.step_1.headline') }}</h2>
				<p>{{ $t('campsite.create.step_1.description') }}</p>
			</CCol>
		</CRow>
		<CRow class="mt-3">
			<CCol class="position:relative">
				
				<GMapMap
					ref="myMapRef"
					:center="map.center"
					:zoom="map.zoom"
					@click="handleMapClick"
					style="width: 100%; height: 300px"
				>
					<p  class="mt-3">{{ $t('campsite.create.step_1.find_by_poi')}}</p>
					<CRow>
						<CCol xs="8">
							<GMapAutocomplete
								class="form-control"
								label="test"
								placeholder="Adresse oder POI eingeben"
								@place_changed="setPlace"
							/>
						</CCol>
						<CCol xs="4">
							<CButton 
								component="a"
								type="button"
								color="primary"
								class="float-end"
								@click="getUserPosition"
							>
								<span v-if="findMe.locating === false">{{ $t('campsite.create.button_find_me') }}</span>
								<span v-else>
									<CSpinner variant="grow" component="span" size="sm" aria-hidden="true"/>
									{{ $t('campsite.create.locating') }}
								</span>
							</CButton>
			
						</CCol>
					</CRow>
					<GMapMarker
						:position="map.center"
						:draggable="true"
						@dragend="handlePositionDrop"
					/>
				</GMapMap>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<small class="d-block mt-3">
					<div v-if="currentLocationAddress">
						{{currentLocationAddress.results[0].formatted_address}}
					</div>
					<!-- {{ $t('campsite.latitude') }}: {{ form.latitude }} - {{ $t('campsite.longitude') }}: {{ form.longitude }}<br/> -->
				</small>
			</CCol>
		</CRow>
		<hr/>
		<CRow>
			<CCol class="d-flex flex-row justify-content-end">
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(1,2)">
					{{ $t('general.next') }}
					<FontAwesomeIcon icon="fa-chevron-right" />
				</CButton>
			</CCol>
		</CRow>
	</div>
	<!-- Form step 2: Title, descriptions, country -->
	<div v-if="formSteps.step2" class="create-step-2">
		<CRow class="mt-3">
			<CCol>
				<CFormInput
					type="text"
					id="title"
					:label="$t('general.title')"
					:placeholder="$t('general.title')"
					:value="form.title"
					@input="form.title = $event.target.value"
					v-model:value="form.title"
				/>
			</CCol>
		</CRow>
		<CRow class="mt-3">
			<CCol>
				<CFormInput
					type="text"
					id="teaser"
					:label="$t('general.teaser')"
					:placeholder="$t('general.teaser')"
					:value="form.teaser"
					@input="form.teaser = $event.target.value"
					v-model:value="form.teaser"
				/>
			</CCol>
		</CRow>
		<CRow class="mt-3">
			<CCol>
				<CFormTextarea
					id="description"
					:label="$t('general.description')"
					rows="3"
					:text="$t('general.description-subline')"
					:value="form.description"
					@input="form.description = $event.target.value"
					v-model:value="form.description" 
				/>
			</CCol>
		</CRow>
		<CRow class="mt-3">
			<CCol>
				<CFormSelect
					id="country_id"
					aria-label="Land auswählen"
					v-model="form.country_id"
					:options="countries"
				/>		
			</CCol>
		</CRow>
		<hr/>
		<CRow>
			<CCol class="d-flex flex-row justify-content-between">
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(2,1)">
					<FontAwesomeIcon icon="fa-chevron-left" />
					{{ $t('general.prev') }}
				</CButton>
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(2,3)">
					{{ $t('general.next') }}
					<FontAwesomeIcon icon="fa-chevron-right" />
				</CButton>
			</CCol>
		</CRow>
	</div>
	<!-- Form step 3: pitches, max. stay, costs, generel infos -->
	<div v-if="formSteps.step3" class="create-step-3">
		<div class="h2 mt-4" aria-hidden="true">Allgemeine Merkmale</div>
		<CRow class="mt-3">
			<CCol>
				<CFormInput
					type="text"
					id="pitches"
					:label="$t('campsite.pitches')"
					:placeholder="$t('campsite.pitches')"
					:value="form.pitches"
					@input="form.pitches = $event.target.value"
					v-model:value="form.pitches"
				/>
			</CCol>
			<CCol>
				<CFormInput
					type="text"
					id="max_stay"
					:label="$t('campsite.max_stay')"
					:placeholder="$t('campsite.max_stay')"
					:value="form.max_stay"
					@input="form.max_stay = $event.target.value"
					v-model:value="form.max_stay"
				/>
			</CCol>
		</CRow>
		<CRow class="mt-3">
			<CCol>
				<CFormInput
					type="text"
					id="cost_per_night"
					:label="$t('campsite.costs_per_night')"
					:placeholder="$t('campsite.costs_per_night')"
					description="Wird eine Gebühr erhoben?"
					:value="form.cost_per_night"
					@input="form.cost_per_night = $event.target.value"
					v-model:value="form.cost_per_night"
				/>
			</CCol>
			<CCol>
				<CFormInput
					type="text"
					id="currency"
					:label="$t('campsite.currency')"
					:placeholder="$t('campsite.currency')"
					description="Welche Wähung gilt?"
					:value="form.currency"
					@input="form.currency = $event.target.value"
					v-model:value="form.currency"
				/>	
			</CCol>
		</CRow>
		<hr/>
		<CRow class="mb-2">
			<CCol xs="6">
				<CFormSwitch 
					id="barrierfree"
					label="Barrierefrei"
					description="Ist der Platz für behinderte Camper geeignet?"
					@update-model-value="(checked) => form.barrierfree = checked"
					v-model="form.barrierfree"
				/>
				<CFormSwitch 
					label="Reservierung" 
					id="reservation"
					@update-model-value="(checked) => form.reservation = checked"
					v-model="form.reservation"
				/>
				<CFormSwitch 
					id="environmental_zone"
					label="Umweltzone"
					description="Liegt der Platz in einer Umweltzone?"
					@update-model-value="(checked) => form.environmental_zone = checked"
					v-model="form.environmental_zone"
				/>
				<CFormSwitch 
					id="caravan"
					label="Wohnwagen"
					description="Sind Wohnwagen erlaubt?"
					@update-model-value="(checked) => form.caravan = checked"
					v-model="form.caravan"
				/>
				<CFormSwitch 
					id="wifi"
					label="WLAN"
					description=""
					@update-model-value="(checked) => form.wifi = checked"
					v-model="form.wifi"
				/>
			</CCol>
			<CCol xs="6">
				<CFormSwitch 
					id="guarded"
					label="Bewacht"
					description=""
					@update-model-value="(checked) => form.guarded = checked"
					v-model="form.guarded"
				/>
				<CFormSwitch 
					id="electricity"
					label="Stromanschluss vorhanden?"
					description=""
					@update-model-value="(checked) => form.electricity = checked"
					v-model="form.electricity"
				/>
				<CFormSwitch 
					id="lightning"
					label="Beleuchtung"
					description="Ist der Platz beleuchtet, z.B. durch Laternen?"
					@update-model-value="(checked) => form.lightning = checked"
					v-model="form.lightning"
				/>
				<CFormSwitch 
					id="shading"
					label="Beschattung am Platz"
					description="Z.B. durch Bäume, Büsche o.ä."
					@update-model-value="(checked) => form.shading = checked"
					v-model="form.shading"
				/>
				<CFormSwitch 
					id="dogs"
					label="Hunde erlaubt"
					description=""
					@update-model-value="(checked) => form.dogs = checked"
					v-model="form.dogs"
				/>
			</CCol>
		</CRow>
		<hr/>
		<CRow>
			<CCol class="d-flex flex-row justify-content-between">
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(3,2)">
					<FontAwesomeIcon icon="fa-chevron-left" />
					{{ $t('general.prev') }}
				</CButton>
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(3,4)">
					{{ $t('general.next') }}
<FontAwesomeIcon icon="fa-chevron-right" />
				</CButton>
			</CCol>
		</CRow>
	</div>
	<!-- Form step 4: sanitary facilities -->
	<div v-if="formSteps.step4" class="create-step-4">
		<div class="h2 mt-4" aria-hidden="true">Sanitäre Anlagen</div>
		<CRow class="mb-2">
			<CCol xs="6">
				<CFormSwitch 
					id="toilets"
					label="Toiletten"
					description="Sanitäre Anlagen vorhanden?"
					@update-model-value="(checked) => form.toilets = checked"
					v-model="form.toilets"
				/>
			</CCol>
			<CCol xs="6">
				<CFormSwitch 
					id="showers"
					label="Duschen"
					description="Sanitäre Anlagen vorhanden?"
					@update-model-value="(checked) => form.showers = checked"
					v-model="form.showers"
				/>
			</CCol>
		</CRow>

		<div class="h2 mt-4" aria-hidden="true">Ver- und Entsorgung</div>
		<CRow class="mb-2">
			<CCol xs="6">
				<CFormSwitch 
					id="washing_sink"
					label="Spülbecken"
					description=""
					@update-model-value="(checked) => form.washing_sink = checked"
					v-model="form.washing_sink"
				/>
				<CFormSwitch 
					id="washing_machine"
					label="Waschmaschine"
					description=""
					@update-model-value="(checked) => form.washing_machine = checked"
					v-model="form.washing_machine"
				/>
				<CFormSwitch 
					id="clothes_dryer"
					label="Trockner"
					description=""
					@update-model-value="(checked) => form.clothes_dryer = checked"
					v-model="form.clothes_dryer"
				/>
				<CFormSwitch 
					id="freshwater_supply"
					label="Frischwasser"
					description=""
					@update-model-value="(checked) => form.freshwater_supply = checked"
					v-model="form.freshwater_supply"
				/>
				<CFormSwitch 
					id="freshwater_connection"
					label="Frischwasseranschluss"
					description="Direkt am Stellplatz?"
					@update-model-value="(checked) => form.freshwater_connection = checked"
					v-model="form.freshwater_connection"
				/>
			</CCol>
			<CCol xs="6">
				<CFormSwitch 
					id="graywater_disposal"
					label="Grauwasserentsorgung"
					description=""
					@update-model-value="(checked) => form.graywater_disposal = checked"
					v-model="form.graywater_disposal"
				/>
				<CFormSwitch 
					id="graywater_connection"
					label="Grauwasseranschluss"
					description="Direkt am Stellplatz?"
					@update-model-value="(checked) => form.graywater_connection = checked"
					v-model="form.graywater_connection"
				/>
				<CFormSwitch 
					id="toilet_disposal"
					label="Toilettenentsorgung"
					description=""
					@update-model-value="(checked) => form.toilet_disposal = checked"
					v-model="form.toilet_disposal"
				/>
				<CFormSwitch 
					id="garbage_disposal"
					label="Müllentsorgung"
					description=""
					@update-model-value="(checked) => form.garbage_disposal = checked"
					v-model="form.garbage_disposal"
				/>
			</CCol>
		</CRow>
		<hr/>
		<CRow>
			<CCol class="d-flex flex-row justify-content-between">
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(4,3)">
					<FontAwesomeIcon icon="fa-chevron-left" />
					{{ $t('general.prev') }}
				</CButton>
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(4,5)">
					{{ $t('campsite.create.button_summary') }}
					<FontAwesomeIcon icon="fa-chevron-right" />
				</CButton>
			</CCol>
		</CRow>
	</div>
	<!-- Form step 5: Summary & Create -->
	<div v-if="formSteps.step5" class="create-step-5">
		<CRow>
			<CCol>
				<h2>{{ $t('campsite.create.step_6.headline') }}</h2>
				<p>{{ $t('campsite.create.step_6.description_1') }}</p>
				<p>{{ $t('campsite.create.step_6.description_2') }}</p>
			</CCol>
		</CRow>
		<hr/>
		<CRow>
			<CCol>
				<strong>{{ $t('general.description') }}</strong>
				<div v-if="form.description" v-html="form.description"></div>
				<div v-else="form.description">-</div>
			</CCol>
		</CRow>
		<hr/>
		<CRow class="mb-2">
			<CCol xs="12">
				<strong class="mt-4 mb-2">{{ $t('campsite.general')}}</strong>
			</CCol>
			<CCol xs="6">
				{{ $t('campsite.pitches')}}: {{ form.pitches ?? '-' }}
			</CCol>
			<CCol xs="6">
				{{ $t('campsite.max_stay')}}: {{ form.max_stay ?? '-' }}
			</CCol>
			<CCol xs="6" v-for="item in general">
				<div>
					<span v-if="form[item]"><span class="text-primary">✓</span> {{ $t("campsite." + item) }}</span>
					<span v-else>X <s class="text-muted">{{ $t("campsite." + item) }}</s></span>
				</div>
			</CCol>
		</CRow>
		<hr />

		<strong class="mt-4">{{ $t('campsite.sanity')}}</strong>
		<CRow>
			<CCol xs="6" v-for="item in sanity">
				<div>
					<span v-if="form[item]"><span class="text-primary">✓</span> {{ $t("campsite." + item) }}</span>
					<span v-else>X <s class="text-muted">{{ $t("campsite." + item) }}</s></span>
				</div>
			</CCol>
		</CRow>
		<div v-if="form.latitude != 0 && form.longitude != 0">
			<hr />
			<GMapMap
				:center="map.center"
				:zoom="12"
				style="width: width: 100vw; height: 300px"
			>
				<GMapMarker
					:position="{
						lat: form.latitude, 
						lng: form.longitude
					}"
				/>
			</GMapMap>
		</div>
		<hr />
		<CRow>
			<CCol class="d-flex flex-row justify-content-between">
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(5,4)">
					<FontAwesomeIcon icon="fa-chevron-left" />
					{{ $t('general.prev') }}
				</CButton>
				<CButton class="btn btn-primary text-light" type="submit">
					{{ $t('campsite.create.button_submit')}}
				</CButton>
			</CCol>
			
		</CRow>
	</div>

	<!-- Form step 6: Pictures -->
	<div v-if="formSteps.step6" class="create-step-6">
		<div class="h2 mt-4" aria-hidden="true">Bilder hochladen</div>
		<div class="upload">
			<div v-if="files.length" class="images">
				<div class="image" v-for="file in files" :key="file.id">
					<img class="image-preview" width="150" v-if="file.thumb" :src="file.thumb" />
					<button @click.prevent="$refs.upload.remove(file)" class="btn btn-primary">x</button>
					<span v-if="file.error">{{file.error}}</span>
					<span v-else-if="file.success">success</span>
					<span v-else-if="file.active">active</span>
					<span v-else></span>
				</div>
			</div>
			
			<div class="mt-3">
				<FileUpload
					class="btn btn-primary"
					post-action="/upload/post"
					:multiple="true"
					:drop="true"
					:drop-directory="true"
					v-model="files"
					ref="upload"
					@input-filter="fileInputFilter"
				>
					auswählen
				</FileUpload>
				<button 
					type="button" 
					class="btn btn-primary float-end"
					v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true"
				>
					hochladen
				</button>
				<button 
					type="button" 
					class="btn btn-danger" 
					v-else @click.prevent="$refs.upload.active = false"
				>
					{{ $t('general.cancel') }}
				</button>
			</div>
		</div>
		<hr/>
		<CRow>
			<CCol class="d-flex flex-row justify-content-start">
				<CButton class="btn btn-outline-secondary" @click.prevent="formstepsHandler(6,5)">
					<FontAwesomeIcon icon="fa-chevron-left" />
					{{ $t('general.prev') }}
				</CButton>
			</CCol>
		</CRow>

		<hr/>
		<CButton class="btn btn-primary text-light" type="submit">
			{{ $t('campsite.create.button_submit')}}
		</CButton>
	</div>

</CForm>

</template>

<script>
import axios from "axios";
import FileUpload from 'vue-upload-component';
import { 
	CRow, CCol, CForm, CFormInput, CFormCheck, CButton, 
	CSpinner, CFormTextarea, CFormSelect, 
	CFormSwitch 
} from '@coreui/vue';


export default {
	name: "CampsiteCreateForm",
	components: {
		FileUpload,
		CRow,
		CCol,
		CForm,
		CFormInput,
		CFormCheck,
		CFormSelect,
		CButton,
		CSpinner,
		CFormTextarea,
		CFormSwitch,
	},
	data() {
		return {
			general: ['dogs', 'caravan', 'barrierfree', 'reservation', 'environmental_zone', 'wifi'],
			sanity: ['toilets', 'showers', 'guarded', 'washing_sink', 'washing_machine', 'clothes_dryer', 'shading', 'lightning', 'electricity', 'freshwater_supply', 'freshwater_connection', 'graywater_disposal', 'graywater_connection', 'toilet_disposal', 'garbage_disposal'],
			
			formSteps: {
				step1: true,
				step2: false,
				step3: false,
				step4: false,
				step5: false,
				step6: false
			},
			currentLocationAddress: false,
			files: [],
			findMe: {
				locating: false,
			},
			map: {
				zoom: 5,
				center: {
					lat: 56, 
					lng: 5
				},
			},
			form: {
				user_id: this.$store.state.auth.user.id,
				country_id: '',
				visibility: "public",
				title: "",
				teaser: "",
				description: "",
				cost_per_night: "",
				currency: "€",
				ground: 1,
				pitches: 0,
				max_stay: 0,
				dogs: false,
				caravan: false,
				barrierfree: false,
				reservation: false,
				environmental_zone: false,
				wifi: false,
				toilets: false,
				showers: false,
				guarded: false,
				washing_sink: false,
				washing_machine: false,
				clothes_dryer: false,
				shading: false,
				lightning: false,
				electricity: false,
				freshwater_supply: false,
				freshwater_connection: false,
				graywater_disposal: false,
				graywater_connection: false,
				toilet_disposal: false,
				garbage_disposal: false,
				latitude: 0.00,
				longitude: 0.00
			}
		};
	},
	
	computed: {
		countries() {
			let options = [];
			this.$store.state.countries.forEach( (element) => {
				options.push({
					value: element.id,
					label: element.name
				});
			})
			return options;
		}
	},
	methods: {
		
		Media( value ){
			this.media = value
		},
		fileInputFilter( newFile, oldFile, prevent) {

			if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
				// Create a blob field
				newFile.blob = ''
				let URL = (window.URL || window.webkitURL)
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file)
				}
				// Thumbnails
				newFile.thumb = ''
				if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
					newFile.thumb = newFile.blob
				}
			}

		},
		formstepsHandler(current = null, target = null) {
			this.formSteps['step'+current] = false;
			this.formSteps['step'+target] = true;
		},
		setPlace( event ) {
			let lat = event.geometry.location.lat();
			let lng = event.geometry.location.lng();

			this.map.zoom = this.map.zoom <= 14 ? 14 : this.map.zoom;
			this.map.center = {
				lat: lat, 
				lng: lng 
			};

			// resolve address based on the coords by reverse geocoding
			let apiKey = 'AIzaSyBPkxxprrUFZnu5gbxh2Ub4ZZXYiRggeA0';
			let url ='https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+apiKey;
			
			axios.get( url ).then((response) => {
				this.currentLocationAddress = response.data;
			});
			
			// update form values
			this.form.latitude = lat;
			this.form.longitude = lng;		
		},
		handleMapClick( event ) {
			let lat = event.latLng.lat();
			let lng = event.latLng.lng();

			this.map.center = { lat: lat, lng: lng };

			// resolve address based on the coords by reverse geocoding
			let apiKey = 'AIzaSyBPkxxprrUFZnu5gbxh2Ub4ZZXYiRggeA0';
			let url ='https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+apiKey;
			
			axios.get( url ).then((response) => {
				this.currentLocationAddress = response.data;
			});

			// update form values
			this.form.latitude = lat;
			this.form.longitude = lng;		
		},
		handlePositionDrop(event) {
			let lat = event.latLng.lat();
			let lng = event.latLng.lng();

			this.map.zoom = this.map.zoom <= 10 ? 10 : this.map.zoom;
			this.map.center = { 
				lat: lat, 
				lng: lng
			};

			let apiKey = 'AIzaSyBPkxxprrUFZnu5gbxh2Ub4ZZXYiRggeA0';
			let url ='https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+apiKey;
			
			axios.get( url ).then((response) => {
				this.currentLocationAddress = response.data;
			});

			// update form values
			this.form.latitude = lat;
			this.form.longitude = lng;

		},
		getUserPosition() {
			this.findMe.locating = true;
			navigator.geolocation.getCurrentPosition(
				position => {
					let lat = position.coords.latitude; 
					let lng = position.coords.longitude; 

					let apiKey = 'AIzaSyBPkxxprrUFZnu5gbxh2Ub4ZZXYiRggeA0';
					let url ='https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+apiKey;
					
					axios.get( url ).then((response) => {
						this.currentLocationAddress = response.data;
					});

					// center map with new position
					this.map.zoom = this.map.zoom <= 14 ? 14 : this.map.zoom;
					this.map.center = { 
						lat: lat,
						lng: lng
					};

					// update form values
					this.form.latitude = lat;
					this.form.longitude = lng;

					// disable spinner on button
					this.findMe.locating = false;
				},
				error => {
					console.log(error.message);
				},
			)   
		},
		
		handleCreateCampsite() {	
			this.$store.dispatch("campsite/createCampsite", this.form).then(
				( response ) => {
					this.$router.push({name: 'campsiteDetail', params: { itemId: response.id }});
				},
				( error ) => {
					this.message = (
						error.response &&
						error.response.data &&
						error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
}

</script>

<style lang="scss">
.upload {
	.images {
		.image {
			width: 150px;
			height: 150px;
		}
	}
}
</style>