import axios from 'axios';
import store from '../store/index';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
	login(user) {
		return axios
			.post(API_URL + 'login', {
				email: user.email,
				password: user.password
			})
			.then(response => {
				localStorage.setItem('user', JSON.stringify(response.data));
				return response.data;
			});
	};
	logout() {
		store.state.campsites = null;
		localStorage.removeItem('user');
	};
	register(user) {
		return axios.post(API_URL + 'users', {
			firstname: user.firstname,
			lastname: user.lastname,
			nickname: user.nickname,
			email: user.email,
			password: user.password
		},
		{
			headers: { "Content-Type": "multipart/form-data" }
		});
	}
}
export default new AuthService();