<template>
	<div>
		<CCard v-if="campsite" class="rounded-0 border-0" style="padding: 71px 0">	
			<carousel v-if="campsite.images.length && campsite.images.length > 1" :items-to-show="1">
				<slide v-for="image in campsite.images" :key="image">
					<CCardImage :src="appUrl + '/storage/files/' + image.name" class="rounded-0" />
				</slide>
				<template #addons>
					<navigation />
					<div class="d-flex px-3 pe-0 pt-2 align-items-center justify-content-between">
						<LikeButton :campsite="campsite"/>
						<pagination />
						<CButton v-if="currentUser.id == campsite.user.id" class="btn py-0" @click="itemMenuVisible = true">
							<FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" class="px-1" />
						</CButton>
					</div>
				</template>
			</carousel>
			<div v-else-if="campsite.images.length && campsite.images.length == 1">
				<CCardImage 
					:src="appUrl + '/storage/files/' + campsite.images[0].name"
					class="rounded-0"
				/>
			</div>
			<div v-else class="d-flex justify-content-center bg-light p-5">
				<CButton class="mx-auto btn-primary" @click="uploadModalVisible = true">Bilder hochladen</CButton>
			</div>

			<div v-if="campsite.images.length == 1" class="p-3 pb-0 d-flex justify-content-between mb-1">
				<LikeButton :campsite="campsite" class="me-2"/>
				<CButton v-if="currentUser.id == campsite.user.id" class="btn py-0" @click="itemMenuVisible = true">
					<FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" class="px-1" />
				</CButton>
			</div>
			<CCardText class="px-3 pb-0 m-0 small">{{ $t('general.liked_by') }} {{campsite.likes_count}} {{ campsite.likes_count === 1 ? $t('general.person') : $t('general.persons') }}</CCardText>
			<CCardBody>	
				<CCardTitle class="d-flex mt-4 justify-content-between">
					{{ campsite.title }}
					<small v-if="campsite.cost_per_night" class="costs">
						{{ campsite.cost_per_night}} {{ campsite.currency }}/Tag
					</small>
				</CCardTitle>
				<CCardText class="mt-n2"><small>{{campsite.country.name}}</small></CCardText>
				<CCardText>
					{{ campsite.teaser }}
				</CCardText>
				<div v-html="campsite.description"></div>
				<hr/>
				<CCardTitle class="mt-4">{{ $t('campsite.general')}}</CCardTitle>
				<CRow class="mb-2">
					<CCol xs="6">
						{{ $t('campsite.pitches')}}: {{campsite.pitches }}
					</CCol>
					<CCol xs="6">
						{{ $t('campsite.max_stay')}}: {{campsite.max_stay }}
					</CCol>
				</CRow>
				<CRow>
					<CCol xs="6" v-for="item in general">
						<CCardText>
							<span v-if="campsite[item]"><span class="text-primary">✓</span> {{ $t("campsite." + item) }}</span>
							<span v-else>X <s class="text-muted">{{ $t("campsite." + item) }}</s></span>
						</CCardText>
					</CCol>
				</CRow>
				<hr/>
				<CCardTitle class="mt-4">{{ $t('campsite.sanity')}}</CCardTitle>
				<CRow>
					<CCol xs="6" v-for="item in sanity">
						<CCardText>
							<span v-if="campsite[item]"><span class="text-primary">✓</span> {{ $t("campsite." + item) }}</span>
							<span v-else>X <s class="text-muted">{{ $t("campsite." + item) }}</s></span>
						</CCardText>
					</CCol>
				</CRow>
				<hr/>
				<GMapMap
					:center="center"
					:zoom="12"
					:options="mapOptions"
					style="width: width: 100vw; height: 300px"
				>
					<GMapMarker
						:position="marker.position"
						:clickable="true"
						@click="center=marker.position"
					/>
				</GMapMap>
				<hr/>
				<small class="text-muted">{{ convertDate(campsite.created_at) }}</small>
			</CCardBody>
		</CCard>

		<CModal 
			:visible="uploadModalVisible"
			@close="() => { uploadModalVisible = false; this.updateCampsite() }"
			fullscreen="sm"
		>
			<CModalHeader class="border-0">
				<CModalTitle class="fs-4">{{ $t('upload.headline') }}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<div class="upload">
					<div v-if="currentUser.id == campsite.user.id && uploadModalVisible">
						<div class="drop-zone d-flex flex-column align-items-center p-4 border border-2 border-dark border-opacity-25 rounded bg-light">
							<FontAwesomeIcon v-if="files.length == 0" icon="fa-regular fa-image" class="mb-4 extra-large text-dark opacity-25" />
							<p class="fs-4 mb-1"><strong>{{ $t('upload.drag_here') }}</strong></p>
							<p class="fs-6">{{ $t('upload.or') }}</p>

							<FileUpload
								v-model="files"
								class="btn btn-primary "
								name="file"
								ref="upload"
								extensions="gif,jpg,jpeg,png,webp"
								accept="image/png,image/gif,image/jpeg,image/webp"
								:post-action="apiUrl + 'sites/storeFile'"
								:multiple="true"
								:drop="true"
								:drop-directory="false"
								:size="1024 * 1024 * 2"
								@input-filter="inputFilter"
								@input-file="inputFile"
								:headers="{
									'Authorization': 'Bearer ' + this.$store.state.auth.user.token,
									'withCredentials': true,
								}"
								:data="{
									site: campsite.id,
								}"
							>
								{{ $t('upload.btn.browse') }}
							</FileUpload>
						</div>
						<div class="d-flex justify-content-end mt-2">
							<button v-if="files.length > 0" @click="files = []" class="btn btn-sm">
								alle löschen
								<FontAwesomeIcon icon="fa-regular fa-trash-can" />
							</button>
						</div>
						<div v-for="file in files" :key="file.id" :id="'image-' + file.id" class="d-flex mx-n3 py-2 px-3 align-items-center justify-content-between border-0 border-top border-opacity-25">
							
							<img v-if="file.thumb" class="upload-thumb" :src="file.thumb"/>
							<span v-else class="upload-thumb">{{ $t('upload.noimage') }}</span>
							
							<div class="flex-grow-1 small px-2">
								<div style="height: 4px" class="progress" v-if="(file.active || file.progress !== '0.00') && (!file.success && !file.error)">
									<div 
										:class="{
											'progress-bar': true,
											'progress-bar-striped': true,
											'bg-danger': file.error,
											'progress-bar-animated': file.active
										}"
										role="progressbar" 
										:style="{width: file.progress + '%'}"
									></div>
								</div>
								<strong>{{file.name}}</strong><br>
								<span v-if="file.active" class="text-success pe-2">{{ $t('upload.uploading') }}</span>
								{{ $t('upload.filesize') }}: {{ humanFileSize(file.size, true) }}
								<span v-if="file.error" class="small text-danger">{{ $t('general.error') }}: {{ $t('upload.errors.' + file.error) }}</span>
							</div>

							<a v-if="!file.success && !file.active && !file.error" href="#" class="btn btn-sm thumb-drop d-flex align-items-center text-primary justify-content-center" @click.prevent="$refs.upload.remove(file)">
								<FontAwesomeIcon icon="fa-regular fa-trash-can" />
							</a>
							<span v-if="file.success" class="text-success p-2">
								<FontAwesomeIcon icon="fa-regular fa-circle-check" />
							</span>
							<span v-else-if="file.error" class="text-danger p-2">
								<FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
							</span>
							<span v-else-if="!!file.error" class="text-danger p-2">
								<FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
							</span>
							<span v-else></span>
						</div>
					</div>
				</div>
			</CModalBody>
			<CModalFooter class="border-0">
				<CButton color="secondary" @click="() => { uploadModalVisible = false }">{{ $t('general.cancel') }}</CButton>
				<CButton color="primary" :disabled="!files.length > 0" v-if="!upload || !upload.active" @click.prevent="upload.active = true">{{ $t('upload.btn.upload') }}</CButton>
			</CModalFooter>
		</CModal>

		<CModal 
			:visible="imageEditorModalVisible"
			@close="() => { imageEditorModalVisible = false; this.updateCampsite() }"
			fullscreen="sm"
		>
			<CModalHeader class="border-0">
				<CModalTitle class="fs-4">{{ $t('imagemodal.headline') }}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<p v-html="$t('imagemodal.description')"></p>
				<div v-for="image in campsite.images" :key="image" class="d-flex mx-n3 py-2 px-3 align-items-center justify-content-between border-0 border-top border-opacity-25">
					<CImage 
						:src="appUrl + '/storage/files/' + image.name "
						class="upload-thumb rounded-0"
					/>
					<div class="flex-grow-1 small px-2">{{image.file_name}}</div>
					<CButton class="btn" @click="deleteImage( image )">
						<FontAwesomeIcon icon="fa-regular fa-trash-can" />
					</CButton>
				</div>

			</CModalBody>
			<CModalFooter class="border-0">
				<CButton color="primary" @click="() => { imageEditorModalVisible = false }">{{ $t('general.close') }}</CButton>
			</CModalFooter>
		</CModal>

		<COffcanvas placement="bottom" :visible="itemMenuVisible" @hide="() => { itemMenuVisible = !itemMenuVisible }">
			<COffcanvasHeader class="rounded-4">
				<COffcanvasTitle>Funktionen</COffcanvasTitle>
				<CCloseButton class="text-reset" @click="() => { itemMenuVisible = false }"/>
			</COffcanvasHeader>
			<COffcanvasBody>
				<CButton class="btn btn-primary me-2" @click="uploadModalVisible = true">
					{{$t('campsite.functions_menu.images.btn.upload')}}
				</CButton>
				<CButton class="btn btn-primary me-2" @click="imageEditorModalVisible = true">
					{{$t('campsite.functions_menu.images.btn.edit')}}
				</CButton>
				<CButton class="btn btn-primary" @click="deleteCampsite">
					{{$t('general.delete')}}
				</CButton>
			</COffcanvasBody>
		</COffcanvas>

	</div>
</template>

<script>

import {ref} from 'vue';
import axios from "axios"
import 'vue3-carousel/dist/carousel.css';
import LikeButton from '@/components/LikeButton.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import { 
	CImage,
	CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter,
	CRow, CCol,
	CAvatar, CButton,
	CCard, CCardGroup, CCardImage, CCardHeader, CCardBody, CCardText, CCardTitle, CCardFooter,
	COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, COffcanvasBody
} from '@coreui/vue';


export default {
	name: 'CampsiteDetail',

	components: { 
		LikeButton,
		CImage, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter,
		CRow, CCol,
		CAvatar, CButton,
		CCard, CCardGroup, CCardImage, CCardHeader, CCardBody, CCardText, CCardTitle, CCardFooter,
		Carousel, Slide, Pagination, Navigation,
		COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, COffcanvasBody

	},
	data() {
		return {
			appUrl: process.env.VUE_APP_URL,
			apiUrl: process.env.VUE_APP_API_URL,
			campsite: false,
			uploadModalVisible: false,
			imageEditorModalVisible: false,
			itemMenuVisible: false,
			general: ['dogs', 'caravan', 'barrierfree', 'reservation', 'environmental_zone', 'wifi'],
			sanity: ['toilets', 'showers', 'guarded', 'washing_sink', 'washing_machine', 'clothes_dryer', 'shading', 'lightning', 'electricity', 'freshwater_supply', 'freshwater_connection', 'graywater_disposal', 'graywater_connection', 'toilet_disposal', 'garbage_disposal'],
			center: { lat: 0, lng: 0 },
			mapOptions: {
				// mapId: '6139e05c81dcfc1d'		// add custom styling via cloud styling option
			},
			marker:	{
				position: { lat: 0, lng: 0 },
			}
		}
	},
	setup(props, context) {
		const upload = ref(null)
		const files = ref([])

		function inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				// Before adding a file
				// Filter system files or hide files
				if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
					return prevent()
				}
				
				// Filter php html js file
				if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
					return prevent()
				}

				// Crop image and create thumbnail
				if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
					// Create a blob field
					newFile.blob = ''
					let URL = (window.URL || window.webkitURL)
					if (URL) {
						newFile.blob = URL.createObjectURL(newFile.file);
					}
					// Thumbnails
					newFile.thumb = '';
					if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
						newFile.thumb = newFile.blob
					}
					
					// crop uploaded image to a squared format
					let image = new Image();
					image.src = newFile.blob;
					image.addEventListener('load', function() {
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');
						let 
							sourceWidth = image.width, 
							sourceHeight = image.height,
							sx = 0, sy = 0,
							dx = 0, dy = 0,
							destinationWidth = 1024,
							destinationHeight = 1024;

						// orig image is smaller than the destination 
						if( image.width < destinationWidth || image.height < destinationHeight ) {
							if( image.width > image.height ) {
								// landscape
								destinationWidth = image.height;
								destinationHeight = image.height;
							} else {
								// portrait
								destinationWidth = image.width;
								destinationHeight = image.width;
							}
						}
						
						canvas.id = newFile.id;
						canvas.width = destinationWidth;
						canvas.height = destinationHeight;

						if( image.width > image.height ) {
							// landscape
							sx = (image.width - image.height) / 2;
							sourceWidth = image.height;
							sourceHeight = image.height;
						} else {
							// portait
							sourceWidth = image.width;
							sourceHeight = image.width;
						}
						ctx.drawImage(image, sx, sy, sourceWidth, sourceHeight, dx, dy, destinationWidth, destinationHeight);
						canvas.toBlob( (blob)=>{
							console.log( newFile.size);
							newFile.file = blob;
							newFile.size = newFile.file.size;
							console.log( newFile.size);
						});
					});

				}
			}
		}
		function inputFile(newFile, oldFile) {
			if (newFile && !oldFile) {
				// add
				// console.log('add', newFile)
				
			}
			if (newFile && oldFile) {
				// update
				// console.log('update', newFile)
			}
			if (!newFile && oldFile) {
				// remove
				// console.log('remove', oldFile)
			}
		}
		return {
			files,
			upload,
			inputFilter,
			inputFile,
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	created() {
		this.$store.dispatch('campsite/loadCampsiteById', this.$route.params.itemId ).then(( campsite ) => {
			this.campsite = campsite;
			this.center = {
				lat: this.campsite.latitude,
				lng: this.campsite.longitude
			},
			this.marker.position = this.center;
		});
	},
	methods: {
		deleteCampsite() {
			let url = this.apiUrl + 'sites/' + this.campsite.id;
			let headers = {
				Authorization: 'Bearer ' + this.$store.state.auth.user.token,
				'Content-Type': "multipart/form-data",
				Accept: 'application/json',
				withCredentials: true
			}	

			axios.delete( url, {headers} )
				.then(response => {
					this.itemMenuVisible = false;
					this.$store.dispatch('campsite/loadCampsites');
					this.$router.push('/');
				});
		},
		deleteImage( image ) {
			let url = this.apiUrl + 'sites/deleteFile/'+this.campsite.id+'/'+image.id;
			let headers = {
				Authorization: 'Bearer ' + this.$store.state.auth.user.token,
				'Content-Type': "multipart/form-data",
				Accept: 'application/json',
				withCredentials: true
			}	

			axios
				.delete( url, {headers} )
				.then(response => {
					this.updateCampsite();
				});
		},
		convertDate( value ) {
			let date = new Date( value );
			let result =   (date.getDate() > 9 ? '' : '0') + date.getDate()
				+'.'
				+( (date.getMonth() + 1) > 9 ? '' : '0') + ( date.getMonth() + 1 )
				+'.'
				+date.getFullYear()
			return result
		},
		humanFileSize( bytes, si=false, dp=1 ) {
			const thresh = si ? 1000 : 1024;

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}

			const units = si 
				? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
				: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			const r = 10**dp;

			do {
				bytes /= thresh;
				++u;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
			return bytes.toFixed(dp) + ' ' + units[u];
		},
		updateCampsite() {
			this.$store.dispatch(
				'campsite/loadCampsiteById', 
				this.$route.params.itemId 
			).then(( campsite ) => {
				this.campsite = campsite;
			});
		}
	},
}
</script>

<style>
	.extra-large {
		font-size: 8rem;
	}
	.drop-zone {
		--cui-border-style: dotted;
	}
	.upload-thumb {
		width: 50px;
		height: 50px;
		object-fit: cover;
	}
	.thumb-drop {
		width: 30px;
		height: 30px;
		color: black;
	}
	.truncate {
		text-overflow: ellipsis;
	
		/* Needed to make it work */
		overflow: hidden;
		white-space: nowrap;
	}

	.carousel__prev, .carousel__next {
		background: rgba(255,255,255,0.3);
		border-radius: 50%;
	}
</style>