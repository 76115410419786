<template>
	<FontAwesomeIcon 
		:class="{ 'text-danger': currentUserHasLiked }" 
		@click="likeCampsite" 
		icon="fa-regular fa-heart"
	/>
</template>

<script>

export default {
	name: 'LikeButton',
	props: {
		campsite: null,
		class: ''
	},

	computed: {	
		currentUserHasLiked() {
			return this.campsite.likes.find( like => like.user_id == this.$store.state.auth.user.id ) ? true : false;
		}
	},

	methods: {
		likeCampsite() {
			let likeAction = this.currentUserHasLiked ? 'unlike' : 'like';
			this.$store.dispatch('campsite/' + likeAction + 'Campsite', this.campsite ).then(()=>{
				this.$store.dispatch('campsite/loadCampsites');
			});
		},
	}
}
</script>
