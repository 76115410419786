<template>
	<CRow style="padding: 100px 0"
		:xs="{ cols: 1, gutter: 4}" 
		:md="{ cols: 2 }"
	>
		<template v-for="campsite in campsites">
			<CCol xs class="my-0">
				<CCard class="rounded-0 border-0">
					<CCardHeader class="d-flex px4 py-3 align-items-center bg-white border-0">
						<CAvatar 
							class="border border-primary border-2 me-2" 
							:src="campsite.user.image 
								? appUrl + campsite.user.image 
								: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"/>
	
						<div class="fw-bold">{{ campsite.user.nickname }}</div>
					</CCardHeader>
	
					<carousel v-if="campsite.images.length && campsite.images.length > 1" :items-to-show="1">
						<slide v-for="image in campsite.images" :key="image">
							<CCardImage 
								:src="appUrl + '/storage/files/' + image.name "
								class="rounded-0"
								@click="detailView( campsite )" 
							 />
						</slide>
						<template #addons>
							<navigation />
							<div class="d-flex px-3 pe-0 pt-2 align-items-center justify-content-between">
								<LikeButton :campsite="campsite"/>
								<pagination />
								<div>&nbsp;</div>
							</div>
						</template>
					</carousel>

					<CCardImage v-else-if="campsite.images.length && campsite.images.length == 1"
						:src="appUrl + '/storage/files/' + campsite.images[0].name "
						class="pe-auto rounded-0"
						@click="detailView( campsite )" 
					/>
					
					<CCardBody>
						<div 
							v-if="campsite.images.length && campsite.images.length == 1"
							class="d-flex justify-content-start mb-3"
						>
							<LikeButton :campsite="campsite" class="me-2"/>
						</div>
						<CCardTitle 
							@click="detailView( campsite )" 
							class="d-flex justify-content-between"
						>
							{{ campsite.title }}
						</CCardTitle>
						<CCardText>{{ $t('general.liked_by') }} {{campsite.likes_count}} {{ campsite.likes_count === 1 ? $t('general.person') : $t('general.persons') }}</CCardText>
						<CCardText @click="detailView( campsite )">{{ campsite.teaser }}</CCardText>
						<small class="text-muted">{{  convertDate(campsite.created_at) }}</small>
					</CCardBody>
				</CCard>
				<hr v-if="campsite.images.length" />
			</CCol>
		</template>
	</CRow>
</template>

<script>
import { CAvatar, CRow, CCol, CCardGroup, CCard, CCardImage, CCardHeader, CCardBody, CCardText, CCardTitle, CCardFooter } from '@coreui/vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import LikeButton from '@/components/LikeButton.vue';


export default {
	name: 'CampsitesList',
	components: { 
		LikeButton,
		CAvatar,
		CRow, CCol,
		CCardGroup, CCard, CCardImage, CCardHeader, CCardBody, CCardText, CCardTitle, CCardFooter, 
		Carousel, Slide, Pagination, Navigation
	},
	data() {
		return {
			appUrl: process.env.VUE_APP_URL,
			apiUrl: process.env.VUE_APP_API_URL,
		}
	},
	computed: {
		campsites() {
			if(!this.$store.getters['campsite/getCampsites']) {
				this.$store.dispatch('campsite/loadCampsites');
			}
			return this.$store.getters['campsite/getCampsites'];
		}
	},
	methods: {
		convertDate( value ) {
			let date = new Date( value );
			let result =   (date.getDate() > 9 ? '' : '0') + date.getDate()
				+'.'
				+((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1)
				+'.'
				+date.getFullYear();
			return result;
		},
		detailView( campsite ) {
			this.$router.push({
				name: 'campsiteDetail', 
				params: { itemId:(campsite.id) }
			});
		}
	}
}
</script>

