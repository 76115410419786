<template>
	<CampsitesList />
</template>

<script>
import CampsitesList from '@/components/CampsitesList.vue'

export default {
	name: "Home",
	components: { CampsitesList },
};
</script>