<template>

	<CNavbar placement="fixed-top" color-scheme="light" class="bg-white border-bottom border-1">
		<CContainer>
			<CNavbarBrand>
				<router-link to="/">
					<CImage src="img/logo-campsite-header.png" height="45" />
				</router-link>
			</CNavbarBrand>
			<CAvatar 
				class="avatar-lg border border-primary border-2 me-2" 
				v-if="loggedIn"
				@click="() => { visible = !visible }" 
				:src="currentUser.image 
					? appUrl + currentUser.image
					: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"/>

		</CContainer>
	</CNavbar>

	<div v-if="updateExists" style="z-index:10000; margin-top: 150px" class="bg-primary">
	An update is available
	<div class="btn" @click="refreshApp">Update</div>
	</div>

	<CContainer class="px-0 h-100">
		<router-view />
	</CContainer>

	<CNavbar v-if="loggedIn" placement="fixed-bottom" color-scheme="light" class="bg-white border-top">
		<CContainer class="p-2 px-4">
			<router-link to="/" class="nav-item btn border border-2 border-primary rounded-circle p-2">
				<font-awesome-icon icon="fa-solid fa-caravan" />
			</router-link>
			<router-link to="/search-map" class="nav-item btn border border-2 border-primary rounded-circle p-2">
				<font-awesome-icon icon="fa-solid fa-map-location-dot" />
			</router-link>	
		
			<router-link :to="{name: 'campsiteCreate'}" class="nav-item d-flex justify-content-center align-items-center btn border border-2 border-primary rounded-circle p-2">
				<strong>+</strong>
			</router-link>


		</CContainer>
	</CNavbar>

	<COffcanvas placement="end" :visible="visible" @hide="() => { visible = !visible }">
		<COffcanvasHeader>
			<CCloseButton class="text-reset" @click="() => { visible = !visible }"/>
		</COffcanvasHeader>
		<COffcanvasBody>
			<router-link to="/profile" class="py-3 border-top nav-link" @click="() => { visible = !visible }">
				<font-awesome-icon icon="fa-regular fa-user" />
				<span class="ms-3">Dein Profil</span>
			</router-link>
			<router-link :to="{name: 'userCampsites' }" class="py-3 border-top nav-link" @click="() => { visible = !visible }">
				<font-awesome-icon icon="fa-solid fa-caravan" />
				<span class="ms-3">Deine Einträge</span>
			</router-link>
			<router-link to="/about" class="py-3 border-top border-bottom nav-link" @click="visible = false">
				<font-awesome-icon icon="fa-solid fa-camera" />
				<span class="ms-3">Über campsite</span>
			</router-link>
		</COffcanvasBody>
		<hr/>
		<div class="p-3" @click="visible = false">
			<a href="#" @click.prevent="logout" class="btn btn-primary">Logout</a>
		</div>
		
	</COffcanvas>

</template>

<script>
import { 
	CToaster, CToast, CToastHeader, CToastBody,
	CAvatar, CImage,
	CContainer, CButton, CCloseButton,
	CNavbar, CNavbarNav, CNavLink, CNavbarBrand, 
	CNavItem, CNavbarToggler, CCollapse, COffcanvas,
	COffcanvasHeader, COffcanvasTitle, COffcanvasBody	
} from '@coreui/vue'

//import update from './mixins/update';

export default {
	name: 'App',
	components: { 
		CToaster, CToast, CToastHeader, CToastBody,
		CAvatar, CImage,
		CContainer, CButton, CCloseButton,
		CNavbar, CNavbarNav, CNavLink, CNavbarBrand, CNavItem, CNavbarToggler, CCollapse,
		COffcanvas, COffcanvasHeader, COffcanvasTitle, COffcanvasBody
	},
	data() {
		return {
			appUrl: process.env.VUE_APP_URL,
			apiUrl: process.env.VUE_APP_API_URL,
			visible: false,
			updateExists: false
		}
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	created() {
		this.$store.dispatch('loadCountries');
	},
	methods: {
		logout() {
			this.$store.dispatch('auth/logout');
			this.$router.push('/login');
		},
		refreshApp() {
			window.location.reload();
		}
	}
};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
}
.nav-item{ width: 45px; height: 45px; }

</style>