<template>
	<div class="avatar-editor">
		<div class="avatar-upload" v-show="!edit">
			<div class="text-center p-2">
				<label for="avatar">
					<img 
						:src="files.length 
							? files[0].url 
							: currentUser.image 
								? appUrl + currentUser.image
								: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"
						class="rounded-circle"
					/>
				</label>
			</div>
			<div class="text-center p-2">
				<FileUpload
					extensions="gif,jpg,jpeg,png,webp"
					accept="image/png,image/gif,image/jpeg,image/webp"
					name="avatar"
					class="btn btn-primary"
					:headers="{'Authorization': 'Bearer ' + currentUser.token }"
					:post-action="apiUrl + 'avatar/' + currentUser.id"
					:drop="false"
					v-model="files"
					@input-filter="inputFilter"
					@input-file="inputFile"
					ref="upload">
					Upload avatar
				</FileUpload>
			</div>
		</div>

		<div class="avatar-edit" v-show="files.length && edit">
			<div class="avatar-edit-image" v-if="files.length">
				<img ref="editImage" :src="files[0].url" />
			</div>
			<div class="text-center p-4">
				<button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear">Cancel</button>
				<button type="submit" class="btn btn-primary" @click.prevent="editSave">Save</button>
			</div>
		</div>

	</div>
</template>


<script>
import Cropper from 'cropperjs';
import FileUpload from 'vue-upload-component';

export default {

	components: { FileUpload },
	
	data() {
		return {
			appUrl: process.env.VUE_APP_URL,
			apiUrl: process.env.VUE_APP_API_URL,
			files: [],
			edit: false,
			cropper: false,
		}
	},
	
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},		
	},

	watch: {
		edit(value) {
			if (value) {
				this.$nextTick(function () {
					if (!this.$refs.editImage) {
						return;
					}
					let cropper = new Cropper(this.$refs.editImage, {
						aspectRatio: 1 / 1,
						viewMode: 1,
					});
					this.cropper = cropper;
				})
			} else {
				if (this.cropper) {
					this.cropper.destroy();
					this.cropper = false;
				}
			}
		}
	},

	methods: {
		editSave() {
			this.edit = false;
			let oldFile = this.files[0];
			let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1]);
			let arr = new Uint8Array(binStr.length);

			for (let i = 0; i < binStr.length; i++) {
				arr[i] = binStr.charCodeAt(i);
			}
			let file = new File([arr], oldFile.name, { type: oldFile.type })
			this.$refs.upload.update(oldFile.id, {
				file,
				type: file.type,
				size: file.size,
				active: true,
			});
		},

		alert(message) {
			alert(message)
		},

		inputFile(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				this.$nextTick(function () {
					this.edit = true
				});
			}
			if (!newFile && oldFile) {
				this.edit = false;
			}
			if (newFile && oldFile && !newFile.active && oldFile.active) {
				// update user image in auth.user store
				this.$store.dispatch('setUserImage', newFile.response.data.image);
			}
		},
		
		inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
					this.alert('Deine Auswahl ist keine Bilddatei.')
					return prevent()
				}
			}
			
			
			if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
				
				newFile.url = ''
				let URL = window.URL || window.webkitURL
				if (URL && URL.createObjectURL) {
					newFile.url = URL.createObjectURL(newFile.file);
				}
			}
		}
	}
}
</script>


<style lang="scss">
.avatar-editor {
	.avatar-upload .rounded-circle {
		width: 200px;
		height: 200px;
	}
	.text-center .btn {
		margin: 0 .5rem;
	}
	.avatar-edit-image {
		max-width: 100%;
		img {
			display: block;
			max-width: 100%;
			max-height: 70vh;
		}
	}
}
</style>