import axios from "axios"
let apiUrl = process.env.VUE_APP_API_URL;

export const campsite = {

	namespaced: true,

	state: {
		campsites: null,
		currentCampsite: null,
		userCampsites: null
	},
	
	getters: {
		getCampsites(state) {
			return state.campsites;
		},
		getCurrentCampsite(state) {
			return state.currentCampsite;
		},
		getUserCampsites(state) {
			return state.userCampsites;
		},
		getCampsiteById: (state) => (id) => {
			return state.campsites.find( campsite => campsite.id === id )
		}
	},

	mutations: {
		setCampsites(state, campsites) {
			state.campsites = campsites;
		},
		setCurrentCampsite(state, campsite) {
			state.currentCampsite = campsite;
		},
		setUserCampsites(state, campsites) {
			state.userCampsites = campsites;
		},
		updateCampsite(state, payload){
			// update an extisitng item if payload.index is given,
			// else add as new item as first element in the list
			payload.index != 'undefined' || payload.index != null
				? state.campsites[ payload.index ] = payload.campsite
				: state.campsites.unshift( payload.campsite );
		},
	},

	actions: {
		loadCampsites({ commit }) {
			let url = apiUrl + 'sites';
			return new Promise((resolve, reject) => {
				axios.get( url,  { headers: {Authorization: 'Bearer ' + this.state.auth.user.token}}  )
					.then((response) => {
						commit('setCampsites', response.data.data);
						resolve(response.data.data);
					}, error => {
						// http failed, let the calling function know that action did not work out
						reject(error);
					});

			});

			
		},
		loadCampsiteById({ commit }, id) {
			return new Promise((resolve, reject) => {
				let url = apiUrl + 'sites/' + id;
				axios.get( url, { headers: {Authorization: 'Bearer ' + this.state.auth.user.token}} )
					.then((response) => {
						let existsAtIndex = this.state.campsite.campsites.findIndex((c) => c.id == response.data.data.id);
						commit('updateCampsite', {
							index: existsAtIndex, 
							campsite: response.data.data 
						});
						commit( 'setCurrentCampsite', response.data.data );
						resolve(response.data.data);
					}, error => {
						// http failed, let the calling function know that action did not work out
						reject(error);
					});
			})
		},
		setCurrentCampsite({ commit }, campsite) {
			commit('setCurrentCampsite', campsite);
		},
		loadUserCampsites({ commit }, userId) {
			let url = apiUrl + 'sites/user/' + userId;
			axios.get( url, { headers: { Authorization: 'Bearer ' +this.state.auth.user.token }} )
			.then((response) => {
				commit('setUserCampsites', response.data.data);
			})
		},
		createCampsite({ commit }, campsite) {
			return new Promise((resolve, reject) => {
				let url =  apiUrl + 'sites';
				axios.post(url, campsite, {
					headers: {
						Authorization: 'Bearer ' + this.state.auth.user.token,
						'Content-Type': "multipart/form-data",
						Accept: 'application/json',
						withCredentials: true
					}				
				})
				.then((response) => {
					commit('updateCampsite', { campsite: response.data.data });
					resolve(response.data.data);
				}, error => {
					// http failed, let the calling function know that action did not work out
					reject(error);
				});
			});
			
		},
		likeCampsite({ commit }, campsite) {
			let url =  apiUrl + 'sites/like/' + campsite.id
			axios.get(url, {headers: {Authorization: 'Bearer ' + this.state.auth.user.token}} )
				.then(
					(response) => {
						let existsAtIndex = this.state.campsite.campsites.findIndex((c) => c.id == response.data.data.id);
						commit('updateCampsite', {
							campsite: response.data.data 
						});
						commit(
							'setCurrentCampsite', 
							response.data.data
						);
					},
					(error) => {
						console.log(error);
					});
		},
		unlikeCampsite({ commit }, campsite) {
			let url =  apiUrl + 'sites/unlike/' + campsite.id
			axios.get( url, {headers: {Authorization: 'Bearer ' + this.state.auth.user.token}})
				.then((response) => {
					let existsAtIndex = this.state.campsite.campsites.findIndex((c) => c.id == response.data.data.id);
					commit('updateCampsite', {index: existsAtIndex, campsite: response.data.data });
					commit('setCurrentCampsite', response.data.data);
				});
		}
	},
};
